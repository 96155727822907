import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"
import { Container } from "../components/Container2"
import styled from "styled-components"

const PrivacyPolicyOuterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  & h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0.5em;
  }
`

const PrivacyPolicyInnerWrapper = styled.div`
  width: 80%;
  max-width: 840px;
`

const PrivacyPolicy = ({ data }: { data: any }) => {
  return (
    <Layout>
      <Helmet title="Personvernerklæring">
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div style={{ width: "50px", height: "12vw", maxHeight: "130px" }} />
      <PrivacyPolicyOuterWrapper>
        <PrivacyPolicyInnerWrapper>
          <Container blocks={data.sanityPrivacyPolicy._rawContent} />
        </PrivacyPolicyInnerWrapper>
      </PrivacyPolicyOuterWrapper>
    </Layout>
  )
}

export default PrivacyPolicy

export const query = graphql`
  {
    sanityPrivacyPolicy {
      _rawContent
    }
  }
`
